import React, { Component } from 'react'
import styled from 'styled-components'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Header from '../components/Header'
import Footer from '../components/footer'
import moment from 'moment'
import 'moment/locale/de'
import doctorIcon from '../images/doctor-icon.svg'
import calendarIcon from '../images/calendar-icon-white.svg'
import clockIcon from '../images/clock-icon.svg'
import envelopeIcon from '../images/envelope.svg'
import phoneIconWhite from '../images/phone-icon-white.svg'
import {Link} from "gatsby";
import axios from 'axios';
import { URL } from '../utils/utils';


const timeSlots = [
  '08:00',
  '08:30',
  '09:00',
  '09:30',
  '10:00',
  '10:30',
  '11:00',
  '11:30',
  '12:00',
  '12:30',
  '13:00',
  '13:30',
  '14:00',
  '14:30',
  '15:00',
  '15:30',
  '16:00',
  '16:30',
  '17:00',
  '17:30',
];


const TitleSection = styled.div`
  margin: 120px 0 20px;
  display: flex;
  flex-flow: column;
  align-items: center;
  text-align: center;

  @media(max-width: 1184px) {
    align-items: flex-start;
    text-align: left;
    margin: 80px auto 24px;
    width: 95%;
  }
`;

const TagSection = styled.div`
  display: flex;
  align-items: center;
`;

const Tag = styled.h1`
  font-size: 32px;
  line-height: 78px;
  margin-top: 1px;

  @media(max-width: 1184px) {
    line-height: 45px;
  }
`;

const LineLeft = styled.div`
  height: 1px;
  width: 194px;
  background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
  margin-right: 20px;

  @media(max-width: 1184px) {
    display: none;
  }
`;

const LineRight = styled.div`
  height: 1px;
  width: 194px;
  background: linear-gradient(270deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
  margin-left: 20px;

  @media(max-width: 1184px) {
    display: none;
  }
`;

const ContactData = styled.div`
  margin: 60px 0;
  text-align: center;
  color: white;

  p {
    font-size: 12px;
  }
  
  strong {
    font-weight: bold;
    color: #fff;
    font-size: 1.2em;
  }
`;

const NoDoctorInfoText = styled.p`
  margin: 50px 0 100px;
  text-align: center;
`;

const DoctorButtonsWrapper = styled.div`
  text-align: center;
`;

const DoctorButton = styled.button`
  outline: none;
  margin: 20px 10px;
  margin-bottom: 20px;
  background: ${props => props.selected ? '#203bbc' : '#0B164A'};
  border-radius: 5px;
  color: ${props => props.selected ? '#fff' : '#C8D1FF'};
  padding: 13px 30px;
  font-size: 16px;
  transition: all .2s;
`;

const ChangeWeekWrapper = styled.div`
  margin: 20px 50px 20px;

  @media(max-width: 1184px) {
    margin: 80px auto 24px;
    width: 95%;
  }

  h3 {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 10px;
  }
`;

const ChangeWeekArrow = styled.button`
  outline: none;
  padding: 8px 15px;
  background: #1731ad;
  color: #fff;
  border-radius: 5px;
`;

const CurrentWeekButton = styled.button`
  outline: none;
  padding: 8px 15px;
  margin: 0 10px;
  background: #1731ad;
  color: #fff;
  border-radius: 5px;
`;

const SelectedRange = styled.span`
  margin-left: 20px;
  margin-right: 40px;
  color: #fff;
  font-family: 'Poppins', sans-serif;
  font-size: 13px;
`;

const CalendarWrapper = styled.div`
  margin: 20px 0 20px;
  color: #fff;
  display: flex;
  font-family: 'Poppins', sans-serif;
  font-size: 13px;

  @media(max-width: 1184px) {
    margin: 20px auto 24px;
    width: 95%;
  }
`;

const HoursColumn = styled.div`
  width: 50px;
`;

const Hour = styled.div`
  height: 80px;

  &:first-of-type {
    margin-top: 40px;
    
    @media(max-width: 600px) {
      margin-top: 80px;
    }
  }
`;

const CalendarColumn = styled.div`
  flex: 1;
  background: #1731ad;
  border-right: 1px solid #94a7ff;

  &:last-of-type {
    border-right: none;
  }
`;

const ColumnHeader = styled.div`
  padding: 10px 20px;
  height: 40px;
  display: flex;
  align-items: center;
  
  @media(max-width: 600px) {
    height: 80px;
  }
  
  background: ${props => props.isPastOrToday ? '#263fb6' : 'transparent'}
`;

const TodaysDateIndicator = styled.div`
  display: inline-block;
  margin-left: 5px;
  min-width: 7px;
  min-height: 7px;
  background: #fff;
  border-radius: 50%;
`;

const ColumnSlot = styled.div`
  height: 40px;
  padding: 3px 5px;
  display: flex;
  align-items: center;
  background: ${props => props.isPast ? '#263fb6' : 'transparent'};
`;

const AvailableSlot = styled.div`
  background: #3454ec;
  border-radius: 5px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;

  .arrow {
    color: #3454ec;
    font-size: 1.3em;
  }

  &:hover {
    cursor: pointer;
    background: #fff;
    color: #3454ec;
    transition-duration: 0.5s;
  }
`;

const VisitDetailsWrapper = styled.div`
  margin: 0 auto 20px;
  display: flex;
  flex-flow: column;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  width: 500px;
  padding: 20px;

  @media(max-width: 1184px) {
    align-items: flex-start;
    margin: 80px auto 24px;
    width: auto;
    max-width: 500px;
  }
`;

const CalendarLink = styled.div`
  font-size: 12px;
  color: #c8d1ff;
  display: flex;
  align-items: center;
  cursor: pointer;

  .arrow {
    margin-right: 10px;
    font-size: 1.6em;
  }
`;

const VisitDetailsBox = styled.div`
  margin-top: 10px;
  background: #1731ad;
  border-radius: 5px;
  width: 100%;
  text-align: left;
  color: #fff;
  padding: 20px 30px;
`;

const VisitDetailSubtitle = styled.div`
  margin: 10px 0;
`;

const VisitInfoRow = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  margin-bottom: 5px;

  span {
    width: 25px;
    height: 15px;

    img {
      margin-right: 10px;
      height: 15px;
      width: auto;
    }
  }
`;

const PatientInfoInput = styled.input`
  outline: none;
  width: 100%;
  height: 40px;
  border-radius: 5px;
  border: ${props => props.error ? '1px solid #ff0000' : 'none'};
  padding: 0 20px;
  margin-bottom: 10px;
  font-size: 14px;
`;

const ValidationText = styled.div`
  color: #ff413d;
  margin: 0 0 20px 0;
  font-size: 12px;
`;

const PatientInfoText = styled.div`
  font-size: 13px;
  margin-bottom: 10px;
`;

const ReservationButton = styled.button`
  outline: none;
  width: 100%;
  height: 40px;
  border-radius: 5px;
  background: #219741;
  color: #fff;
  margin-bottom: 10px;
  font-size: 14px;
`;

const VisitSummary = styled.div`
  margin: 0 auto 20px;
  display: flex;
  flex-flow: column;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  width: 500px;
  padding: 20px;
  color: #fff;
  text-align: left;

  @media(max-width: 1184px) {
    align-items: flex-start;
    margin: 80px auto 24px;
    width: auto;
    max-width: 500px;
  }
`;

const EmailInfoBox = styled.div`
  background: #1731ad;
  text-align: center;
  padding: 30px;
  border-radius: 5px;
  margin-top: 20px;
  font-size: 15px;
  
  img {
     height: 24px;
     width: auto;
  }
  
  div {
    margin-top: 20px;
  }
  
`;

const HomepageLinkButton = styled.div`
  text-align: center;
  margin-top: 40px;
  
  a {
    border: 1px solid #fff;
    padding: 10px 20px;
    border-radius: 5px;
  }
`;

const VisitSummaryHeader = styled.div`
  text-align: center;
  font-size: 22px;
  margin-bottom: 20px;
`;

const PatientDataText = styled.div`
  margin-top: 10px;
`;

const OnlineVisit = styled.span`

  .container {
    display: inline-block;
    position: relative;
    padding-left: 22px;
    cursor: pointer;
    user-select: none;
    color: #fff;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;

    &.with-margin-bottom {
      margin-bottom: 15px;
    }
  }

  .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkmark {
    position: absolute;
    top: 2px;
    left: 0;
    height: 16px;
    width: 16px;
    border: 1px solid #ddd;
    border-radius: 3px;
    background: #ddd;
  }

  .container input:checked ~ .checkmark {
    background-color: #ddd;
  }

  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  .container input:checked ~ .checkmark:after {
    display: block;
  }

  .container .checkmark:after {
    left: 4px;
    top: 1px;
    width: 4px;
    height: 8px;
    border: solid #000;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`


const getMonday = () => {
  const today = moment();
  const weekDay = today.isoWeekday();
  const daysShift = weekDay <= 5
    ? (-1 * weekDay) + 1
    : 7 - (weekDay - 1);
  const monday = moment().add(daysShift, 'day');
  return monday;
};


const getHeaders = calendarStartDate => {
  const weekDays = [];
  for (let i = 0; i < 5; i++) {
    weekDays.push(moment(calendarStartDate).add(i, 'day'));
  }
  return weekDays;
}

function validateEmail(email) {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

class KalenderPage extends Component {
  state = {
    page: 1,
    doctor: null,
    bookings: [],
    calendarStartDate: getMonday(),
    headers: [],
    slotId: null,
    doctorName: null,
    visitStartDate: null,
    visitEndDate: null,
    patientName: null,
    patientPhone: null,
    patientEmail: null,
    patientNameError: false,
    patientPhoneError: false,
    patientEmailError: false,
    remoteVisit: true,
  }

  componentDidMount() {
    const initialDoctor = new URLSearchParams(window.location.search).get('doctor')
    const headers = getHeaders(this.state.calendarStartDate)
    this.setState({
      doctor: initialDoctor,
      headers: headers,
    })
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.doctor 
      && (this.state.doctor !== prevState.doctor
          || moment(this.state.headers[0]).format('YYYY-MM-DD') !== moment(prevState.headers[0]).format('YYYY-MM-DD'))
    ) {
      const monday = moment(this.state.headers[0]).format('YYYY-MM-DD') + 'T00:00:00+00:00'
      const friday = moment(this.state.headers[4]).format('YYYY-MM-DD') + 'T23:59:59+00:00'

      axios.get(`${URL}/api/bookings?visit_start_date=${monday}&visit_end_date=${friday}&doctor=${this.state.doctor}`)
        .then(response => {
          this.setState({bookings: response.data})
        })
        .catch(err => console.log(err));
    }
  }

  render() {
    moment.locale('de');

    if (this.state.page === 1) return (
      <>
        <Header />
        <Layout background="linear-gradient(180deg, #0e1c5d, #203bbc)">
          <SEO title="Termin buchen" />
          
          <TitleSection>
            <TagSection>
              <LineLeft />
              <Tag>Termin Buchen</Tag>
              <LineRight />
            </TagSection>
            <p>Sprechstundentermine im Aorten- und GefässZentrum Zürich können bequem online gebucht werden.</p>
          </TitleSection>

          <DoctorButtonsWrapper>
            <DoctorButton
              selected={this.state.doctor === 'dr-varga'}
              onClick={() => {
                this.setState({doctor: 'dr-varga'})
                window.history.pushState("", "", "/kalender/?doctor=dr-varga")
              }}>
                Dr. med. Michael Szente Varga
              </DoctorButton>
            <DoctorButton
              selected={this.state.doctor === 'dr-lachat'}
              onClick={() => {
                this.setState({doctor: 'dr-lachat'})
                window.history.pushState("", "", "/kalender/?doctor=dr-lachat")
              }}>
                Prof. Dr. med. Mario Lachat
              </DoctorButton>
          </DoctorButtonsWrapper>

          {
            !this.state.doctor && <NoDoctorInfoText>Bitte treffen Sie eine Auswahl.</NoDoctorInfoText>
          }

          {
            this.state.doctor && <div>
              <ChangeWeekWrapper>
                <h3>Wählen Sie einen verfügbaren Termin:</h3>
                <ChangeWeekArrow onClick={() => this.setState({
                  calendarStartDate: this.state.calendarStartDate.add(-7, 'day'),
                  headers: getHeaders(this.state.calendarStartDate)
                })} >
                  &lt;
                </ChangeWeekArrow>
                <CurrentWeekButton onClick={() => this.setState({
                  calendarStartDate: getMonday(),
                  headers: getHeaders(getMonday())
                })} >
                  heute
                </CurrentWeekButton>
                <ChangeWeekArrow onClick={() => this.setState({
                  calendarStartDate: this.state.calendarStartDate.add(7, 'day'),
                  headers: getHeaders(this.state.calendarStartDate)
                })} >
                  &gt;
                </ChangeWeekArrow>
                
                <SelectedRange>
                  {`${moment(this.state.headers[0]).format('MMM D')} - ${moment(this.state.headers[4]).format('MMM D, YYYY')}`}
                </SelectedRange>

                <OnlineVisit>
                  <label className="container">Ich wünsche eine Online Sprechstunde
                    <input type="checkbox" checked={this.state.remoteVisit} onChange={() => this.setState({
                      remoteVisit: !this.state.remoteVisit
                    })} />
                    <span className="checkmark"></span>
                  </label>
                </OnlineVisit>
                
              </ChangeWeekWrapper>

              <CalendarWrapper>
                <HoursColumn>
                  {
                    timeSlots.map((hour, index) => index % 2 === 0 && (
                      <Hour key={index}>
                        {hour[0] === '0' ? hour.substr(1) : hour}
                      </Hour>
                    ))
                  }
                </HoursColumn>
                {
                  this.state.headers.map((header, index) => {
                    const today = moment();
                    const isPastOrToday = moment.duration(today.diff(header)).asHours() > 0;

                    return (
                      <CalendarColumn key={index}>
                        <ColumnHeader isPastOrToday={isPastOrToday} >
                          {header.format('ddd, D MMM')}
                          {header.isSame(new Date(), 'day') && <TodaysDateIndicator /> }
                        </ColumnHeader>
                        {
                          timeSlots.map((slot, index) => {
                            const columnDate = header.format('YYYY-MM-DD')
                            const slotFullDate = `${columnDate}-${slot}`
                            const matchingVisitSlot = this.state.bookings.find(slot => moment(slot.visit_start_date).utc().format('YYYY-MM-DD-HH:mm') === slotFullDate)
                            const isSlotInThePast = moment(new Date()).format('YYYY-MM-DD-HH:mm') > slotFullDate

                            return(
                              <ColumnSlot key={index} isPast={isSlotInThePast}>
                                {
                                  matchingVisitSlot
                                    ? <AvailableSlot
                                      onClick={() => this.setState({
                                        page: 2,
                                        slotId: matchingVisitSlot.id,
                                        doctorName: matchingVisitSlot.doctor.name,
                                        visitStartDate: matchingVisitSlot.visit_start_date,
                                        visitEndDate: matchingVisitSlot.visit_end_date,
                                      })} >
                                      <span>{`${moment(matchingVisitSlot.visit_start_date).utc().format('HH:mm')} - ${moment(matchingVisitSlot.visit_end_date).utc().format('HH:mm')}`}</span>
                                      <span className="arrow">&gt;</span>
                                    </AvailableSlot>
                                    : <div/>
                                }
                              </ColumnSlot>
                            )
                          })
                        }
                      </CalendarColumn>
                    )})
                }
              </CalendarWrapper>
            </div>
          }

          <ContactData>
            <p>Bei Fragen kontaktieren Sie bitte unser Sekretariat:</p>
            <p><strong>+41 44 387 29 29</strong></p>
            <p>Gefeass-chirurge</p>
            <p>Klinik Hirslanden</p>
            <p>Witellikerstrasse 40</p>
            <p>CH-8032 Zürich</p>
          </ContactData>
          
        </Layout>
        <Footer />
      </>
    )

    else if (this.state.page === 2) {
      return (
        <>
          <Header />
          <Layout background="linear-gradient(180deg, #0e1c5d, #203bbc)">
            <SEO title="Termin vereinbaren" />
            
            <TitleSection>
              <TagSection>
                <LineLeft />
                <Tag>Termin vereinbaren</Tag>
                <LineRight />
              </TagSection>
            </TitleSection>

            <VisitDetailsWrapper>
              
              <CalendarLink 
                onClick={() => this.setState({
                  page: 1
                })} >
                <span className="arrow">&lt;</span>
                <span>zurück</span>
              </CalendarLink>

              <VisitDetailsBox>
                <VisitDetailSubtitle>Ihr gewählter Termin</VisitDetailSubtitle>
                <VisitInfoRow>
                  <span><img src={doctorIcon} alt="icon" /></span>
                  {this.state.doctorName}
                </VisitInfoRow>
                <VisitInfoRow>
                  <span><img src={calendarIcon} alt="icon" /></span>
                  {moment(this.state.visitStartDate).utc().format('dddd, D MMMM YYYY')}
                </VisitInfoRow>
                <VisitInfoRow>
                  <span><img src={clockIcon} alt="icon" /></span>
                  {`${moment(this.state.visitStartDate).utc().format('HH:mm')} - ${moment(this.state.visitEndDate).utc().format('HH:mm')}`}
                </VisitInfoRow>
                <br/>

                <VisitDetailSubtitle>Informationen zum Patienten</VisitDetailSubtitle>

                <OnlineVisit>
                  <label className="container with-margin-bottom">Ich wünsche eine Online Sprechstunde
                    <input type="checkbox" checked={this.state.remoteVisit} onChange={() => this.setState({
                      remoteVisit: !this.state.remoteVisit
                    })} />
                    <span className="checkmark"></span>
                  </label>
                </OnlineVisit>

                <PatientInfoInput error={this.state.patientNameError}
                  placeholder="Name, Vorname"
                  onChange={e => this.setState({ patientName: e.target.value })}
                />
                <PatientInfoInput error={this.state.patientPhoneError}
                  placeholder="Telefonnummer"
                  onChange={e => this.setState({ patientPhone: e.target.value })}
                />
                <PatientInfoInput error={this.state.patientEmailError}
                  placeholder="Email"
                  onChange={e => this.setState({ patientEmail: e.target.value })}
                />
                {
                  (this.state.patientNameError || this.state.patientPhoneError || this.state.patientEmailError)
                    && <ValidationText>Bitte geben Sie Ihren Namen und mindestens eine der folgenden Angaben an: Telefon oder Emailadresse</ValidationText>
                }

                <PatientInfoText>Wir senden Ihnen einen Bestätigungslink</PatientInfoText>
                <br/>
                <ReservationButton
                  onClick={() =>
                  {
                    this.setState({
                      patientNameError: !this.state.patientName || (this.state.patientName.length < 3),
                      patientPhoneError: (!this.state.patientPhone || (this.state.patientPhone.length < 6)) && !validateEmail(this.state.patientEmail),
                      patientEmailError: (this.state.patientEmail && !validateEmail(this.state.patientEmail)) || ((!this.state.patientPhone || (this.state.patientPhone.length < 6)) && !validateEmail(this.state.patientEmail)),
                    }, () => {
                      if (!this.state.patientNameError && !this.state.patientPhoneError && !this.state.patientEmailError) {

                        axios.put(`${URL}/api/bookings/${this.state.slotId}`, {
                          is_reserved: true,
                          patient_name: this.state.patientName,
                          patient_email: this.state.patientEmail,
                          patient_phone: this.state.patientPhone,
                          is_remote: this.state.remoteVisit,
                        })
                        .then(response => {
                          if (response.status === 200) {
                            this.setState({
                              page: 3
                            })
                          }
                        })
                        .catch(err => console.log(err));
                      }
                    })
                  }} >
                    reservieren
                </ReservationButton>
              </VisitDetailsBox>
            </VisitDetailsWrapper>

            <ContactData>
              <p>Bei Fragen kontaktieren Sie bitte unser Sekretariat:</p>
              <p><strong>+41 44 387 29 29</strong></p>
              <p>Gefeass-chirurge</p>
              <p>Klinik Hirslanden</p>
              <p>Witellikerstrasse 40</p>
              <p>CH-8032 Zürich</p>
            </ContactData>
            
          </Layout>
          <Footer />
        </>
      )
    }

    else if (this.state.page === 3) {
      return (
        <>
          <Header />
          <Layout background="linear-gradient(180deg, #0e1c5d, #203bbc)">
            <SEO title="Termin buchen" />

            <TitleSection>
              <TagSection>
                <LineLeft />
                <Tag>Termin Buchen</Tag>
                <LineRight />
              </TagSection>
            </TitleSection>

            <VisitSummary>
              <VisitSummaryHeader>Zusammenfassung</VisitSummaryHeader>

              <VisitDetailSubtitle>Ihr gewählter Termin</VisitDetailSubtitle>
              <VisitInfoRow>
                <span><img src={doctorIcon} alt="icon" /></span>
                {this.state.doctorName}
              </VisitInfoRow>
              <VisitInfoRow>
                <span><img src={calendarIcon} alt="icon" /></span>
                {moment(this.state.visitStartDate).utc().format('dddd, D MMMM YYYY')}
              </VisitInfoRow>
              <VisitInfoRow>
                <span><img src={clockIcon} alt="icon" /></span>
                {`${moment(this.state.visitStartDate).utc().format('HH:mm')} - ${moment(this.state.visitEndDate).utc().format('HH:mm')}`}
              </VisitInfoRow>

              <br/>

              <PatientDataText>{this.state.patientName}</PatientDataText>
              <PatientDataText>{this.state.patientPhone}</PatientDataText>
              <PatientDataText>{this.state.patientEmail}</PatientDataText>

              <br/>

              <EmailInfoBox>
                <img src={validateEmail(this.state.patientEmail) ? envelopeIcon : phoneIconWhite} alt="icon" />
                <div> Wir haben Ihnen einen Bestätigungslink zugestellt. Bitte bestätigen Sie Ihre Buchung innerhalb der nächsten 30 Minuten.</div>
                <div>Falls Sie keine Mailadresse hinterlegt haben, werden wir Sie per SMS kontaktieren.</div>
                <div>Im Verhinderungsfalle bitten wir Sie um Benachrichtigung. Vielen Dank!</div>
              </EmailInfoBox>

              <HomepageLinkButton>
                <Link to="/">
                  Zurück zur Startseite
                </Link>
              </HomepageLinkButton>

            </VisitSummary>

            <ContactData>
              <p>Bei Fragen kontaktieren Sie bitte unser Sekretariat:</p>
              <p><strong>+41 44 387 29 29</strong></p>
              <p>Gefeass-chirurge</p>
              <p>Klinik Hirslanden</p>
              <p>Witellikerstrasse 40</p>
              <p>CH-8032 Zürich</p>
            </ContactData>

          </Layout>
          <Footer />
        </>
      )
    }

  }
}

export default KalenderPage
